import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import ContactMain from '../components/contactMain'

const Contact = ({ data }) => {
  const heroImage = data.heroImage
  return (
    <Layout>
      <div id="sub-page">          
        <Hero
          class="intro-65"
          image={heroImage.childImageSharp.gatsbyImageData}
          title="Need to get in touch?"
          subtitle="Fill out the form below and we'll get back to you."
          type="contact"
          alt="hand holding a paper plane ready to throw"
        />
      </div>

      <main>
        <ContactMain />
      </main>
    </Layout>
  )
}
export default Contact

export function Head({ data, location }) {
  const heroImage = data.heroImage
  return (
    <SEO
      title="Contact Us"
      description="Want more information on USRobotics solutions and services?  Please get in touch using the contact details or web form provided."
      url={location.pathname} 
      image={heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query {
    markdownRemark(
      fields: {}
      frontmatter: { template: { eq: "other" }, name: { eq: "contact" } }
    ) {
      html
      frontmatter {
        meta {
          title
          description
        }
        section {
          title
          subtitle
          description
        }
        title
        subtitle      
      }
      html
    }
    heroImage: file(name: { eq: "contact-us" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  }
`